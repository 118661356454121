import React from 'react'

function Fulfillment() {
  const divStyle = {
    'max-height': "80vh"
  };
  return (
    <section >
      <section className='bg-cloudDancer p-0 m-0'>

        <div className="container col-xxl-8 px-4 py-5 ">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src="https://ndc.ssabel.com/imgs/josh-hild-Vuxe1ZTAQsQ-global.jpg" className="d-block mx-lg-auto img-fluid scimg" alt="Global Growth" loading="lazy" >
              </img>
            </div>
            <div className="col-lg-6">
              <h1 className="display-3 cFB900 lh-1 mb-3 text-turkishSea">Fulfillment</h1>
              <p className="lead display-5 text-center cFB600 text-xenonBlue">
                Where you want it.</p>

              <blockquote className="blockquote pt-3 mt-2">
                <hr className='p-0 my-0 mx-3' />
                <p className='cFB600 text-center pt-1'>With our global networks,
                 we can cut down order processing time and ensure a fast-tracked shipping process to your desired address. </p>
              </blockquote>

            </div>
          </div>
        </div>

      </section>
      <section className='bg-gardenia fw-bold'>
        <div className="px-4 pt-5  text-center border-bottom">
          <p className="display-3 cFB800 text-peacockBlue">Global Fulfillment</p>

          <div className="container px-4 py-5" >

            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#toggles2" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Expertise</h2>
                  <p className='fw-bold'>
                    Our team is trained to sift through products to source the best quality
                     in the market while maintaining a high-profit markup and ensuring they are shipped out to the correct address. </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#cpu-fill" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Development</h2>
                  <p>Adopting new ways of automating the fulfillment process like multiple layers of quality control and order tracking helps
                     to keep customer satisfaction at the forefront of our operations.</p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#tools" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Strategy</h2>
                  <p>With the expansion to global markets, setting up fulfillment centers in 
                    major cities will ensure that products are easily accessible to everyone without long waiting periods. 
                    By increasing the pool of suppliers risks are spread and orders can be redirected in case of inadequate inventory.

                  </p>
                </div>
              </div>
            </div>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#toggles2" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Communication</h2>
                  <p>
                    In fulfillment constant engagement with the suppliers as well as
                     customers is paramount. Our team ensures that parties involve have all relevant information 
                    with them at all times. We also ensure that our team can easily be reached at all times offering quick and effective feedback.</p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#cpu-fill" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Synergy</h2>
                  <p>
                    As we scale up our business our limits are tested and to keep up with the new demand everything has to flow through a proper system. 
                    Integration is important in ensuring as we buff up every organization employed is up to the task. </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#tools" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Teamwork</h2>
                  <p>
                    The fulfillment process operates through a specific chain of command which is fostered by meeting each of the parties involved's 
                    expectations and creating long-term relations that can be leveraged in the future.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
            <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3">Primary button</button>
            <button type="button" className="btn btn-outline-secondary btn-lg px-4">Secondary</button>
          </div> */}

          <div className="overflow-hidden " style={divStyle}>
            <div className="container px-5">
              <img src="https://ndc.ssabel.com/imgs/andy-li-CpsTAUPoScw-adj.jpg" className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example" loading="lazy" />
            </div>
          </div>
        </div>
      </section>

    </section>
  )
}

export default Fulfillment