import React, { useState } from 'react'

import './ContactForm.css'
import { Link } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { database } from './Firebase'
import { ref, push, child, update } from "firebase/database";

function ContactForm() {
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [businessEmail, setBusinessEmail] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null); 
    const [message, setMessage] = useState(null); 

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === "firstName") {
            setFirstName(value);
        }
        if (id === "lastName") {
            setLastName(value);
        }
        if (id === "businessEmail") {
            setBusinessEmail(value);
        }
        if (id === "companyName") {
            setCompanyName(value);
        }
      
        if (id === "message") {
            setMessage(value);
        }

    }
    const handleSubmit = () => {
        let obj = {
            firstName: firstName,
            lastName: lastName,
            email: businessEmail,
            companyName: companyName,
            phoneNumber: phoneNumber,
            message:message
        }
        const newPostKey = push(child(ref(database), 'contactReqs')).key;
        const updates = {};
        updates['/' + newPostKey] = obj
        return update(ref(database), updates);
    }
  return (
      <div className="homeContact d-flex h-100 text-center text-white bg-dark">

          <div className="container col-xl-10 col-xxl-8 px-4 py-5">
              <div className="row align-items-center g-lg-5 py-5">
                  <div className="col-lg-7 text-center text-lg-start">
                      <h1 className="display-2 fw-bold lh-1 mb-3 text-illuminating">Contact Us</h1>
                      <p className="col-lg-10 fs-2 cFB600 text-end">
                          Grow your business in new markets with a partner that puts people first.
                      </p>
                  </div>
                  <div className="col-md-10 mx-auto col-lg-5 text-fruitDove ">
                      <form className="p-4 p-md-5 border rounded-3 bg-cloudDancer">
                          <div className='row  mb-3 g-1'>
                              <div class="col form-floating">
                                  <input type="text"  className="form-control text-fruitDove " id="firstName" 
                                   value={firstName} onChange={(e) => handleInputChange(e)}
                                  placeholder="John" />
                                  <label for="firstName">First Name</label>
                                  </div>
                              <div class="col form-floating">
                                  <input type="text" className="form-control " id="lastName"
                                      value={lastName} onChange={(e) => handleInputChange(e)}
                                       placeholder="Snow" />
                                  <label for="lastName">Last Name</label>
                              </div>
                        </div>
                          
                          <div className="form-floating mb-3">
                              <input type="email" className="form-control" id="businessEmail"
                                  value={businessEmail} onChange={(e) => handleInputChange(e)}
                                   placeholder="name@example.com"/>
                              <label for="businessEmail">Business Email address</label>
                          </div>
                          <div className="form-floating mb-3">
                              <input type="text" className="form-control" id="companyName"
                                  value={companyName} onChange={(e) => handleInputChange(e)}
                              placeholder="Company Name"/>
                              <label for="companyName">Company Name</label>
                          </div>
                          <div className="form-floating mb-3">
                              <PhoneInput
                              country={'us'}
                              
                              onChange={(value)=>{setPhoneNumber({value})}}
                                 
                              />
                          </div>
                          <div className="form-floating mb-3">
                              <textarea rows={3} className="form-control" id="message"
                                  value={message} onChange={(e) => handleInputChange(e)}
                                  placeholder="Type your message here"> </textarea>
                              <label for="message">Message</label>
                          </div>
                          <button className=" btn  btn-outline-illuminating" type="submit" onClick={()=>handleSubmit()}>
                            <p className='p-0 m-0 cFB800 text-fruitDove'>
                                  Get in Touch
                            </p>
                           </button>
                          <hr className="my-4" />
                          <small className="text-muted">We care about your privacy. By submitting this form, you will receive the requested information,
                           as well as occasional related business insights from Ssabel. You can unsubscribe at any time.</small>
                      </form>
                  </div>
              </div>
          </div>





      </div>
  )
}

export default ContactForm