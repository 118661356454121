import React from 'react'

function ProcuSourcing() {
  const divStyle = {
    'max-height': "80vh"
  };
  return (
    <section >
      <section className='bg-cloudDancer p-0 m-0'>

        <div className="container col-xxl-8 px-4 py-5 ">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src="https://ndc.ssabel.com/imgs/aron-yigin-lNpAmLA_bvQ-unsplash.jpg" className="d-block mx-lg-auto img-fluid scimg" alt="Global Growth" loading="lazy" >
              </img>
            </div>
            <div className="col-lg-6">
              <h1 className="display-3 cFB900 lh-1 mb-3 text-turkishSea">Global Procurement &amp; Sourcing</h1>
              <p className="lead display-5 text-center cFB600 text-xenonBlue">
                Source transformatively.</p>

              <blockquote className="blockquote pt-3 mt-2">
                <hr className='p-0 my-0 mx-3' />
                <p className='cFB600 text-center pt-1'>
                Well-designed sourcing initiatives can generate substantial savings
                  – up to 10 to 15 percent of a company’s purchasing costs and in some cases as much as 35 to 40 percent.</p>
              </blockquote>

            </div>
          </div>
        </div>

      </section>
      <section className='bg-gardenia '>
        <div className="px-4 pt-5  text-center border-bottom">
          <p className="display-3 cFB800 text-peacockBlue">Global Sourcing</p>

          <div className="container px-4 py-5" >

            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#toggles2" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Expertise</h2>
                  <p>
                    We leverages our international procurement expertise, 
                    market knowledge and global supply chain networks to provide the best value for clients' capital investments.
                  </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#cpu-fill" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Development</h2>
                  <p>

                    New product innovation must be supported by excellence and efficiency in the product development and
                     sourcing processes. Engaging key manufacturing parties early allows for parallel development and production 
                     ramp processes–helping our clients achieve greater flexibility and speed-to-market.

                  </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#tools" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Strategy</h2>
                  <p>We implement enterprise wide strategic sourcing programs or across specific purchasing categories that 
                    are collaborative and have a systematic approach for dramatically reducing external spending,
                      while improving quality, internal processes and total cost.

                  </p>
                </div>
              </div>
            </div>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#toggles2" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Communication</h2>
                  <p>
                    The big challenge of cross-cultural procurement is to face customers and suppliers with different nationalities,
                     not only to understand their needs, thinking modes and working habits but also to blend
                      in with local culture to accelerate both parties' running-in period.
                  </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#cpu-fill" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Synergy</h2>
                  <p>
                    We specialize in cost optimization, risk mitigation, and implementation of sustainable practices.
                    We focus on solving problems, reducing costs, and optimizing processes.
                     We promote enhanced client competencies, and deliver the knowledge and tools for self-sufficiency.
                  </p>
                </div>
              </div>

            </div>
          </div>
          {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
            <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3">Primary button</button>
            <button type="button" className="btn btn-outline-secondary btn-lg px-4">Secondary</button>
          </div> */}

          <div className="overflow-hidden " style={divStyle}>
            <div className="container px-5">
              <img src="https://ndc.ssabel.com/imgs/marcin-jozwiak-oh0DITWoHi4-unsplash.jpg" className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example" loading="lazy" />
            </div>
          </div>
        </div>
      </section>

    </section>
  )
}

export default ProcuSourcing