import React from 'react'

import './GlobalGrowth.css'

function GlobalGrowth() {
  const divStyle = {
    'max-height': "80vh"
  };
  return (
    <section >
      <section className='bg-cloudDancer p-0 m-0'>

      <div className="container col-xxl-8 px-4 py-5 ">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
              <img src="https://ndc.ssabel.com/imgs/josh-hild-Vuxe1ZTAQsQ-global.jpg" className="d-block mx-lg-auto img-fluid scimg" alt="Global Growth" loading="lazy" >
            </img>
          </div>
          <div className="col-lg-6">
            <h1 className="display-3 cFB900 lh-1 mb-3 text-turkishSea">Global Growth</h1>
            <p className="lead display-5 text-center cFB600 text-xenonBlue">
             Dare to think big.</p>
           
            <blockquote className="blockquote pt-3 mt-2">
              <hr className='p-0 my-0 mx-3' />
              <p className='cFB600 text-center pt-1'>Leverage our global platform and deep market expertise to help you identify international
                 opportunities with the most reliable, internally vetted corporate and individual importers
                  in over 240 countries and territories.</p>
            </blockquote>
           
          </div>
        </div>
      </div>
      
      </section>
      <section className='bg-gardenia '>
        <div className="px-4 pt-5  text-center border-bottom">
          <p className="display-3 cFB800 text-peacockBlue">Global Sales</p>
          
            <div className="container px-4 py-5 fw-bold" >
             
              <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                <div className="col d-flex align-items-start">
                <div className='mx-auto text-center  '>
                  {/* <img src='\imgs\expertise.webp' className='adjIm'  alt='exp'/> */}
                   
                  </div>
                  <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Expertise</h2>
                    <p>
                    We have a wide range of expertise and experience
                     in sales and a keen specialty in areas such as agricultural products &amp; inputs, art, collectibles, precious metals etc.
                    </p>
                  </div>
                </div>
                <div className="col d-flex align-items-start">
                  <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                    {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#cpu-fill" /></svg> */}
                  </div>
                  <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Development</h2>
                  <p>Our account managers are constantly adapting our expertise as the upfront sales effort continues to be essential for ‘winning’ new programs!
                    </p>
                  </div>
                </div>
                <div className="col d-flex align-items-start">
                  <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                    {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#tools" /></svg> */}
                  </div>
                  <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Strategy</h2>
                  <p>An evolving global business marketplace causes businesses to frequently evaluate how to maximize
                     revenue potential amid disparate economies. Our strategy is ever adapting to changes in these diverse markets,
                      ensuring measurable success at every step.

                  </p>
                  </div>
                </div>
              </div>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#toggles2" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Communication</h2>
                  <p>
                    Instant global communication today is easier than ever.
                     Having adequate tools and tech in place to ensure you sales managers are accessible to their teams at all times is crucial.
                    We understand the constant follow up and communications tactics that are key to retaining and developing new business.
                  </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
            
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Synergy</h2>
    <p>
      Our global partner network of strategic institutions including
       technology, escrow, banking &amp; money transfer and logistics providers 
                    among others - provides our clients with a smooth sales experience. We ensure that all people, processes, 
                    systems and channels are better aligned allowing us to provide greater business value.
    </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
        
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Teamwork</h2>
        <p>
                    Our team looks forward to discussing your needs to grow your business on a long term basis.
        </p>
                </div>
              </div>
            </div>
            </div>
            {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
              <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3">Primary button</button>
              <button type="button" className="btn btn-outline-secondary btn-lg px-4">Secondary</button>
            </div> */}
          
          <div className="overflow-hidden " style={divStyle}>
            <div className="container px-5">
              <img src="https://ndc.ssabel.com/imgs/andy-li-CpsTAUPoScw-adj.jpg" className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
     
      </section>
  )
}

export default GlobalGrowth