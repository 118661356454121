import React from 'react'

function TechInnovation() {
  const divStyle = {
    'max-height': "80vh"
  };
  return (
    <section >
      <section className='bg-cloudDancer p-0 m-0'>

        <div className="container col-xxl-8 px-4 py-5 ">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src="https://ndc.ssabel.com/imgs/adi-goldstein-EUsVwEOsblE-unsplash.jpg" className="d-block mx-lg-auto img-fluid scimg" alt="Global Growth" loading="lazy" >
              </img>
            </div>
            <div className="col-lg-6">
              <h1 className="display-4 cFB900 lh-1 mb-3 text-turkishSea">Technology  &amp; Innovation</h1>
              <p className="lead display-5 text-center cFB600 text-xenonBlue">
                Optimize outcomes</p>

              <blockquote className="blockquote pt-3 mt-2">
                <hr className='p-0 my-0 mx-3' />
                <p className='cFB600 text-center pt-1'>Expand your reach to global markets through our advanced
                 technology doubled up with our chain of networks as we strive to stay up to par with current trends in the market.</p>
              </blockquote>

            </div>
          </div>
        </div>

      </section>
      <section className='bg-gardenia '>
        <div className="px-4 pt-5  text-center border-bottom">
          <p className="display-3 cFB800 text-peacockBlue">Acquire Innovation</p>

          <div className="container px-4 py-5" >

            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#toggles2" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Expertise</h2>
                  <p>
                    Our brand employs the use of up-to-date technology while engaging with 
                    trained personnel with years of experience on them to help with the brainstorming of new and different ways to operate.</p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#cpu-fill" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Development</h2>
                  <p>We pride ourselves on our across-the-board personal development that 
                    sees both the growth of our business and the advancement of expertise skills to fully take advantage of the pool of resources. </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#tools" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Strategy</h2>
                  <p>The vision for continuous 
                    and fast-tracked growth demands constant tracking of what we can leverage in the market to optimize our sales on a global reach.

                  </p>
                </div>
              </div>
            </div>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#toggles2" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Communication</h2>
                  <p>
                    We have established an interactive presence on all major social
                     platforms ensuring that our clientele can reach us regarding all matters. 
                    We understand the sensitivity of the tasks required to run this business
                     thus our sales teams have an open communication line with their managers at all times. </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#cpu-fill" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Synergy</h2>
                  <p>
                    For a successful global reach, we have partnered with various institutions like 
                    banking, transport, and communication among others to facilitate this.
                     Seamless integration of all this ensures maximization of sales while offering clients a smooth experience
                  </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#tools" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Teamwork</h2>
                  <p>
                    We strive to ensure that your interaction with our
                     team proofs profitable for your business and offers you room for growth on a wider scale.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
            <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3">Primary button</button>
            <button type="button" className="btn btn-outline-secondary btn-lg px-4">Secondary</button>
          </div> */}

          <div className="overflow-hidden " style={divStyle}>
            <div className="container px-5">
              <img src="https://ndc.ssabel.com/imgs/david-guenther-9dcQu2mxuRc-unsplash.jpg" className="img-fluid border rounded-3 shadow-lg mb-4" alt="tech at ssabel" loading="lazy" />
            </div>
          </div>
        </div>
      </section>

    </section>
  )
}

export default TechInnovation