import React from 'react'

import {  Link } from "react-router-dom";
// import { useStateValue } from './StateProvider'
function LowerBar() {
    return (
        <nav className="nav cFB800 " style={{zIndex:'10000'}}>
            <Link className="nav-link  btnWi " to="/web3"><p className='microTr text-greenGlimmer'>Web 3.0</p></Link>
            <Link className="nav-link  btnWi " to="/global-growth"><p className='microTr text-greenGlimmer'>Global Growth</p></Link>
            <Link className="nav-link  btnWi " to="/procurement"><p className='microTr text-greenGlimmer'>Global Procurement & Sourcing</p></Link>
            <Link className="nav-link  btnWi " to="/tech"><p className='microTr text-greenGlimmer'>Tech & Innovation</p></Link>
            <Link className="nav-link  btnWi " to="/compliance"><p className='microTr text-greenGlimmer'>Compliance</p></Link>
           
            <Link className="nav-link  btnWi " to="/fulfillment"><p className='microTr text-greenGlimmer'>Fulfillment</p></Link>

</nav>
    )
}

export default LowerBar
