import React from 'react';
import './hero.css';
import './Compliance.css';

function Compliance() {

  const divStyle = {
    width: "700px",
    height: "500px"
  };

  return (
    <section className='bg-cloudDancer p-0 m-0'>
<section>

      <div className="container col-xxl-8 px-4 py-5 ">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <img src="https://ndc.ssabel.com/imgs/mccabe-coats-v-v2ss7u4Lg-unsplash.jpg" className="d-block mx-lg-auto img-fluid scimg" alt="compliance path" loading="lazy" style={divStyle}>
            </img>
          </div>
          <div className="col-lg-6">
            <h1 className="display-5 cFB900 lh-1 mb-3 text-turkishSea">Compliance</h1>
            <p className="lead cFB600 text-blackenedPearl">
              Effective and comprehensive compliance to both external and internal expectations begins with smart,
              streamlined internal controls and best practices</p>
            <ol className='list-group list-group-numbered list-group-flush bg-transparent cFB600 text-blackenedPearl'>
              <li className='list-group-item bg-transparent'>Contract Compliance.</li>
              <li className='list-group-item bg-transparent'>Preferred vendor spend compliance</li>
              <li className='list-group-item bg-transparent'>Procurement process compliance.</li>
            </ol>
           
          </div>
        </div>
      </div>
      <hr className='p-0 my-0 mx-2' /></section>
      <section className='bg-gardenia '>
        <div className="px-4 pt-5  text-center border-bottom">
          <p className="display-3 cFB800 text-peacockBlue">Beyond Compliance</p>

          <div className="container px-4 py-5" >

            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#toggles2" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Expertise</h2>
                  <p>
                    Our team is experienced in the analysis of any emergent risks on compliance
                     while still making decisions that align with the set requirements to protect both the client and the business.</p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#cpu-fill" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Development</h2>
                  <p>

                    Growth within the business is strictly monitored to ensure no negative compromises are made to propel this.
                     Complying with labor laws and consumer protection policies are some of our core beliefs as a business.

                  </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#tools" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Strategy</h2>
                  <p>Our team is always hard at work to ensure we find ways to work within the expectations of both the government and the public.
                     Our long-term goals for compliance are propelled by the short-term goals we run through daily.

                  </p>
                </div>
              </div>
            </div>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#toggles2" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Communication</h2>
                  <p>
                    Information relayed to the public complies with what we as a team believe in and work towards.
                     Any concerns brought forward are tackled within a set of rules to protect the interest of all parties involved.
                  </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#cpu-fill" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Synergy</h2>
                  <p>
                    Our partners are aware of the code of conduct we ran our operations through and as a prerequisite of working with us,
                     they are expected to adhere to the same to provide greater value to the business.
                  </p>
                </div>
              </div>
              <div className="col d-flex align-items-start">
                <div className="icon-square bg-light text-dark d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                  {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#cpu-fill" /></svg> */}
                </div>
                <div>
                  <h2 className='text-astralAura cFB600 text-capitalize'>Teamwork</h2>
                  <p>
                    Our clients need advice on our approach to situations and the advancements we make while striving to stay in line with the rules and regulations.
                  </p>
                </div>
              </div>

            </div>
          </div>
          {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
            <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3">Primary button</button>
            <button type="button" className="btn btn-outline-secondary btn-lg px-4">Secondary</button>
          </div> */}

          <div className="overflow-hidden w-100" >
            <div className="container ">
              <img src="https://ndc.ssabel.com/imgs/marcin-jozwiak-oh0DITWoHi4-unsplash.jpg" className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
    </section>
    
  )
}

export default Compliance