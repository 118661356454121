

let stashedVis = JSON.parse(localStorage.getItem("stashedVis"));
let stashedCqSt = JSON.parse(localStorage.getItem("stashedCqSt"));

export const initialState = {
   
    vis:stashedVis?stashedVis:[],
    CqSt: stashedCqSt ? stashedCqSt:[],
    addresses:[]




};


function stashVis(setVis){
    localStorage.setItem("stashedVis", JSON.stringify(setVis));
}
function stashCqSt(setCqSt){
    localStorage.setItem("stashedCqSt", JSON.stringify(setCqSt));
}

const reducer = (state, action) => {
    console.log(action);
    switch(action.type){
        case 'SET_USER':
            return{
                ...state,
                user: action.user
            };
        case 'SET_VIS':
            stashVis(action.vis)
            return {
                ...state,
                vis: action.vis
            };
        case 'SET_CqSt':
            stashCqSt(action.cqst)
            return {
                ...state,
                CqSt: action.cqst
            };
    
        
        default :
            return state;

    }

}
export default reducer;