
import React from "react";



import './App.css';

import Header from './components/Header';
import Home from './components/Home';

import { Route, Routes } from "react-router-dom";
import GlobalGrowth from "./components/GlobalGrowth";
import ProcuSourcing from "./components/ProcuSourcing";
import TechInnovation from "./components/TechInnovation";
import Compliance from "./components/Compliance";
import NewsnSoc from "./components/NewsnSoc";
import Fulfillment from "./components/Fulfillment";
import Floor from "./components/Floor";
import ContactForm from "./components/ContactForm";
import Web3 from "./components/Web3";
import AgriProcuring from "./components/AgriProcuring";
function App() {
  return (

    <div className="App container-fluid p-0">
      <Header />
      <Routes>
        <Route index element={  <Home />} />
        <Route path="web3" element={<Web3 />} />
        <Route path="global-growth" element={<GlobalGrowth />} />
        <Route path="procurement" element={<ProcuSourcing />} />
        <Route path="tech" element={<TechInnovation />} />
        <Route path="agri-procuring" element={<AgriProcuring />} />
        <Route path="compliance" element={<Compliance />} />
        <Route path="newssocial" element={<NewsnSoc />} />
        <Route path="fulfillment" element={<Fulfillment />} />
        <Route path="contact" element={<ContactForm />} />
      </Routes>
      <Floor />
    </div>


  );
}

export default App;
