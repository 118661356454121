
import './Home.css'
import './Cover.css'
import { Link } from 'react-router-dom'

function Home() {
 
    

    return (
       
            <div className="home d-flex h-100 text-center text-white bg-dark">

            <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column tilehead">
                <div className=' p-0 m-0'>

                    <main className="px-3">
                        <p className="display-3 cFB800 text-peacockBlue text-center mt-4 pt-3">Value beyond the last mile.</p>
                        <p className="lead cFB800 text-fruitDove"> Bespoke sourcing, procurement, acquisition and sales</p>
                        <p className="lead">
                    
                        <Link to="/">
                            <p  className="btn btn-lg btn-secondary fw-bold border-white bg-white">
                                
                                Talk to us</p>
                        </Link>
                        </p>
                    </main>
                    
                
                    <hr className='py-3' />
                    
                    <p className='display-4 cFB800 text-ultimateGray '>
                    Specialising in
                    </p>
                    </div>
                    <div className="row m-5 d-flex justify-content-evenly">

                   
                    <div className=" col-md-3 m-1 p-0 card  bg-transparent border-0 mb-3" >
                        <img src="https://ndc.ssabel.com/quantitatives%20web30.jpg" className="card-img-top" alt="Agricultural produce" />
                        <div className="card-body tilehead ">
                            <h5 className="card-title cFB800 text-turkishSea ">Web 3.0</h5>

                        </div>
                    </div>
                    <div className=" col-md-3 m-1 p-0 card  bg-transparent border-0 mb-3" >
                        <img src="https://ndc.ssabel.com/hobijist3d-electronics.jpg" className="card-img-top" alt="Agricultural produce" />
                        <div className="card-body tilehead ">
                            <h5 className="card-title cFB800 text-turkishSea ">Electronics</h5>

                        </div>
                    </div>
                    <div className=" col-md-3 m-1 p-0 card  bg-transparent border-0 mb-3" >
                        <img src="https://ndc.ssabel.com/arindustrialequip.jpg" className="card-img-top" alt="Agricultural produce" />
                        <div className="card-body tilehead ">
                            <h5 className="card-title cFB800 text-turkishSea ">Software Solutions</h5>

                        </div>
                    </div>
                    <div className=" col-md-3 m-1 p-0 card  bg-transparent border-0 mb-3" >
                        <img src="https://ndc.ssabel.com/etienne-girardet-precmetals.jpg" className="card-img-top" alt="Agricultural produce" />
                        <div className="card-body tilehead ">
                            <h5 className="card-title cFB800 text-turkishSea ">Precious Metals</h5>

                        </div>
                    </div>
                    
                    <div className=" col-md-3 m-1 p-0 card  bg-transparent border-0 mb-3" >
                        <img src="https://ndc.ssabel.com/ag-agriprod.jpg" className="card-img-top" alt="Agricultural produce" />
                        <div className="card-body tilehead ">
                            <h5 className="card-title cFB800 text-turkishSea ">Bulk Agricultural Produce</h5>

                        </div>
                    </div>
                    <div className=" col-md-3 m-1 p-0 card  bg-transparent border-0 mb-3" >
                        <img src="https://ndc.ssabel.com/arindustrialequip.jpg" className="card-img-top" alt="Agricultural produce" />
                        <div className="card-body tilehead ">
                            <h5 className="card-title cFB800 text-turkishSea ">Industrial Equipment</h5>

                        </div>
                    </div>
                    <p className='display-4 cFB800 text-ultimateGray '>
                        By your side at every step
                    </p>
                    </div>
                   
                </div>



           

        </div>
    )
}

export default Home
