import React from 'react'
import { Link } from 'react-router-dom';
import { CookieGDPR } from './CookieGDPR'
import { useStateValue } from './StateProvider'
import './Floor.css';
function Floor() {
    const [{  CqSt }, dispatch] = useStateValue();
    const deploCq = CqSt?.AcceptAll
    const setCqPol = () => {
        
        dispatch({
            type: 'SET_CqSt',
            cqst: { AcceptAll: true }
        })
    }
    return (
        <section className="bottom-0 bg-cloudDancer">
            {/* {
                !deploCq && <CookieGDPR sake={setCqPol} />
            } */}
           
            <div className="container">
               
                <footer className="py-5 ">
                    <div className="row border-top pt-3">
                        <div className="col-6 col-md-2 mb-3">
                            <p className='cFB800 text-astralAura pl-3'>Company</p>
                            <hr className='p-0 b-0 border-astralAura'></hr>
                            <ul className="nav flex-column cFB600 text-ultimateGray ">
                                
                                <li className="nav-item mb-2"><Link to="/company" className="nav-link p-0 text-muted">Our Company</Link></li>
                                <li className="nav-item mb-2"><Link to="/approach" className="nav-link p-0 text-muted">Our Approach</Link></li>
                                <li className="nav-item mb-2"><Link to="/partner" className="nav-link p-0 text-muted">Partner Network</Link></li>
                                <li className="nav-item mb-2"><Link to="/contact" className="nav-link p-0 text-muted">Contact Us</Link></li>
                            </ul>
                        </div>

                        <div className="col-6 col-md-2 mb-3">
                            <p className='cFB800 text-astralAura pl-3'>Solutions</p>
                            <hr className='p-0 b-0 border-astralAura'></hr>
                            <ul className="nav flex-column cFB600 text-ultimateGray">
                                <li className="nav-item mb-2"><Link to="/global-growth" className="nav-link p-0 text-muted">Global Growth</Link></li>
                                <li className="nav-item mb-2"><Link to="/procurement" className="nav-link p-0 text-muted">Procurement &amp; Sourcing</Link></li>
                                <li className="nav-item mb-2"><Link to="/tech" className="nav-link p-0 text-muted">Tech &amp; Innovation</Link></li>
                                <li className="nav-item mb-2"><Link to="/compliance" className="nav-link p-0 text-muted">Compliance</Link></li>
                                <li className="nav-item mb-2"><Link to="/fulfillment" className="nav-link p-0 text-muted">Fulfillment</Link></li>
                                <li className="nav-item mb-2"><Link to="/agri-procuring" className="nav-link p-0 text-muted">Agri-Procuring</Link></li>
                            </ul>
                        </div>

                        <div className="col-6 col-md-2 mb-3">
                            <p className='cFB800 text-astralAura pl-3'>Technology</p>
                            <hr className='p-0 b-0 border-astralAura'></hr>
                            <ul className="nav flex-column cFB600 text-ultimateGray">
                             
                                <li className="nav-item mb-2"><Link to="/web3" className="nav-link p-0 text-muted">web 3.0 </Link></li>
                                <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-muted">Partner Network</Link></li>
                                <li className="nav-item mb-2"><Link to="/integration" className="nav-link p-0 text-muted">Integration</Link></li>
                            </ul>
                        </div>

                        <div className="col-md-5 offset-md-1 mb-3">
                            <form>
                                <h5 className='cFB800 text-fruitDove'>Subscribe to our newsletter</h5>
                                <p className='cFB600 text-skyDiver'>Monthly digest of what's new and exciting from us.</p>
                                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                                    <label for="newsletter1" className="visually-hidden">Email address</label>
                                    <input id="newsletter1" type="text" className="form-control" placeholder="Email address" /> 
                                        <button className="btn btn-primary" type="button">Subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-center py-4 my-4 border-top">
                        <p className="text-center">
                            <Link to="/" className="mx-auto my-auto">
                                <img className="footer__logo" src="https://ndc.ssabel.com/ssabel%20w.png" alt="SSABEL" />
                            </Link>
                        </p>
                        <p className='cFB600 pt-1 pb-0'>&copy; 2018 - 2024 SSABEL LTD. All rights reserved.
                        <hr/>
                        </p>
                        
                    </div>
                </footer>
            </div>
        </section>
    )
}

export default Floor
