import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'
// import SearchIcon from "@mui/material/Icon"
//import ShoppingBasketIcon from "@mui/material/Icon"
import { useStateValue } from './StateProvider'
//import { auth } from './Firebase'
import LowerBar from './LowerBar'
// import { CookieGDPR } from './CookieGDPR'

function Header() {

    const [{ user, vis }, dispatch] = useStateValue();
    const bis = vis === 'partner' ? ('client') : ('partner')
    const abis = vis === 'partner' ? ('') : ('partner')
    // const deploCq = CqSt?.AcceptAll
    // const login = () => {
    //     if (user) {
    //         auth.signOut();
    //     }
    // }
    const setVis = () => {
        dispatch({
            type: 'SET_VIS',
            vis: bis,
        });
    }
 
    return (<>
     

        <nav className="header py-md-2 z-100 bg-inherit">



            <Link to="/" className="mx-auto my-auto">
                <img className="header__logo" src="https://ndc.ssabel.com/ssabel%20w.png" alt="SSABEL" />
            </Link>
            <div className="header__search d-flex align-items-center">
          

            </div>
            <div className="header__nav">
                <Link className="header__link" to={!user && "/login"}>
                    {/* <div className="header__option" onClick={login}>
                        <span className="header__optionLineOne">{user ? 'Hello' + user.email : ''}</span>
                        <span className="header__optionLineTwo">{user ? 'Sign Out' : 'Sign In'}</span>
                    </div> */}
                </Link>
                <Link className="header__link" to={"/" + abis} >
                    <div className="header__option" onClick={setVis}>

                        <span className="header__optionLineTwo text-capitalize" >
                            {bis}
                        </span>

                    </div>
                </Link>
                <Link className="header__link" to="/integration">
                    <div className="header__option">

                        <span className="header__optionLineTwo">Integration</span>
                    </div>
                </Link>
                <Link className="header__link" to="/newssocial">
                 
                    <div className="header__option">

                        <span className="header__optionLineTwo">News & Society</span>
                    </div>
                </Link>
           
            </div>


        </nav>
        <div className="w-100 m-0 p-0 d-flex justify-content-center position-sticky">
            <LowerBar />
        </div>
    </>

    )
}

export default Header
