import React from 'react'
import agr1 from '../agripro1.jpg'
import agr2 from '../agripro2.jpg'

function AgriProcuring() {
  const divStyle = {
    'max-height': "80vh"
  };
  return (
    <section>
      <section className='bg-cloudDancer p-0 m-0'>

        <div className="container col-xxl-8 px-4 py-5 ">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src={agr1}
                className="d-block mx-lg-auto img-fluid scimg" alt="Agri-procure" loading="lazy" >
              </img>
            </div>
            <div className="col-lg-6">
              <h1 className="display-4 cFB900 lh-1 mb-3 text-turkishSea">Agricultural Produce Procurement Services</h1>
              <p className="lead display-5 text-center cFB600 text-xenonBlue">
                Connecting Farmers to Quality Markets</p>

              <blockquote className="blockquote pt-3 mt-2">
                <hr className='p-0 my-0 mx-3' />
                <p className='cFB600 text-center pt-1'>
                  Join us in creating a resilient and efficient agri-food supply chain.
                  Together, we cultivate growth, quality, and trust.</p>

              </blockquote>

            </div>
          </div>
        </div>

      </section>
      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6">
              <p className='h3'>Challenges in the Supply Chain</p>
              <ul>
                <li>Post-Harvest Losses: Harvested produce quality and quantity can be unpredictable due to climate sensitivity, pests, diseases, and soil conditions. We address this challenge by leveraging cutting-edge agri-technology.</li>
                <li>Quality Compliance for Export and Food Safety: International markets demand strict quality standards. Our system ensures compliance with regulations, safeguarding consumer interests.</li>
                <li>Efficient Movement Along the Supply Chain: Timely movement of produce is crucial. Our streamlined processes minimize delays, ensuring fresh goods reach their destination promptly.</li>
                <li>Traceability and Trust: We establish traceability from farm to table. Consumers trust our brand because they know where their food comes from.</li>
                <li>Sustainable Local Sourcing: Supporting local farmers and promoting sustainable practices is at the core of our ethos. By sourcing locally, we contribute to community well-being and environmental conservation.</li>
              </ul>
            </div>
            <div className="col-md-6">
              <p className='h3'>Why Choose Us?</p>
              <ul>
                <li><strong>Precision:</strong> Our agri-tech platform provides accurate estimates and real-time monitoring.</li>
                <li><strong>Quality Assurance:</strong> We adhere to international standards for export.</li>
                <li><strong>Reliability:</strong> Trust us to deliver consistently.</li>
                <li><strong>Community Impact:</strong> We support local farmers and sustainable practices.</li>
              </ul>
            </div>
          </div>


        </div>


        <div className="overflow-hidden " style={divStyle}>
          <div className="container px-5">
            <img src={agr2} className="img-fluid border rounded-3 shadow-lg mb-4" alt="Agri-Procure" loading="lazy" />
          </div>
        </div>
      </section>
    </section>
  )
}

export default AgriProcuring