
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyC8a5po3dVNHmKjjs44ZWgTPr0djdsjPD8",
  authDomain: "ssabel.firebaseapp.com",
  databaseURL: "https://ssabel-default-rtdb.firebaseio.com",
  projectId: "ssabel",
  storageBucket: "ssabel.appspot.com",
  messagingSenderId: "95299659033",
  appId: "1:95299659033:web:8c238d3a84972981bc5188",
  measurementId: "G-SSB41J4GM8"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const database = getDatabase(app);